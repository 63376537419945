<template>
  <v-dialog
      :value="value"
      width="500"
      @input=" e => $emit('input', e)"
  >
    <v-card>
      <v-card-title class="text-h5 red lighten-2 white--text">
        Shift is expired or closed, or the KKT is not connected!
      </v-card-title>

      <v-card-text class="mt-5 text-h6">
        Please check that the KKT is connected, open a new shift and try again!
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="$emit('input', false)"
        >
          ОК
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ShiftDialog",
  props:['value'],
}
</script>

<style scoped>

</style>
