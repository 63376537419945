export default {
  data() {
    return {
      requiredRule: [(v) => !!v || "Это поле обязательно к заполнению"],
      lnRule: [
        (v) => !!v || "Это поле обязательно к заполнению",
        (v) =>
          !/\s/g.test(v) ||
          "фамилия не может содержать более одного слова или пробелов",
      ],
      phoneRule: [
        (v) => !!v || "This field is required",
        (v) => this.validatePhone(v) || "Номер телефона недействителен",
      ],
      emailRule: [
        (v) => !!v || "This field is required",
        (v) => this.validateEmail(v) || "Электронный адрес недействителен",
      ],
    };
  },
  methods: {
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhone(phone, isRussianCitizen = false) {
      let re;
      if (isRussianCitizen) {
        re = /^[\\+]?7[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
      } else {
        re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
      }
      return re.test(String(phone).toLowerCase());
    }
  },
};
