import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./services/store";
import Axios from "@/services/axios.js";
import loginer from "./services/loginer";
import enums from "./services/enums";

Vue.prototype.$store = store;
Vue.prototype.$enums = enums;

Vue.prototype.$axios = Axios;
Vue.config.productionTip = false;
loginer.trySignIn().then(() => {
  new Vue({
    vuetify,
    render: (h) => h(App),
    router: router,
  }).$mount("#app");
});
