import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import enums from "./enums";

import { toBool } from "./support";
import storeTrainModule from "@/services/store_modules/StoreTrainModule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    price: 0,
    seats: 0,
    trainSegments: [] | undefined,
    token: "",
    api:
      process.env.NODE_ENV === "development"
        ? "https://localhost:44395/api"
        : "https://api.bpo.travel/api",

    apiAvia:
      process.env.NODE_ENV === "development"
        ? "https://localhost:7011/api"
        : "https://api.bpo.travel/api",

    railwayApi:
      process.env.NODE_ENV === "development"
        ? "https://localhost:7159/api"
        : "https://api.railway.myzone.pro/api",
    // https://api-railway.bpo.travel/api

    kktUrl: "http://localhost:8010/proxy/api/v2/requests",
    shiftOpen: localStorage.getItem("shiftOpen") || "",
    shiftExpiry: localStorage.getItem("shiftExpiry") || "",

    company: {
      Name: "BPO Tickets",
      Phone: "+7(495) 215 57 95",
      PhoneLink: "tel:+74952155795",
      Addres: "Москва, ул.Юннатов, д.18",
      AddresLink:
        "https://2gis.ru/moscow/search/%D1%8E%D0%BD%D0%B0%D1%82%D0%BE%D0%B2%2018/geo/4504235282677586?m=37.558967%2C55.802872%2F18.03%2Fp%2F45%2Fr%2F-95.24",
      Email: "info@bpo.travel",
      EmailLink: "mailto:info@bpo.travel",
      Support: "+7(495)215-57-95",
    },

    // dialog confirm
    confirmDialogOpen: false,
    additionalMessage: null,
    acceptAction: () => {},
    cancelAction: () => {
      this.confirmDialogOpen = false;
    },

    // Theme
    darkTheme: false,

    // Error
    error: {
      show: false,
      value: {
        Code: "",
        Message: "",
      },
    },

    // Snackbar notification
    snackbar: {
      show: false,
      message: "",
      color: "",
      timeout: 5000,
      contentClass: "",
    },
  },

  mutations: {
    addTrainSegment(state, newSegment) {
      state.trainSegments = newSegment;
    },
    removeTrainSegment(state, index) {
      state.trainSegments.splice(index, 1);
    },
    clearTrainSegments(state) {
      state.trainSegments = [];
    },
    addPrice(state, price) {
      state.price = price;
    },
    addSeats(state, seats) {
      state.seats += seats; // Update total price
    },
    clearPrice(state, price) {
      state.price = 0;
    },
    updateBookingData(state, newData) {
      state.bookingData = newData;
    },
    // Screen
    setScreenIsActive(state, value) {
      localStorage.setItem("screen-active", value);
      state.screen.isActive = value;
    },
    setScreenType(state, value) {
      localStorage.setItem("screen-type", value);
      state.screen.type = value;
    },

    setScreenPrice(state, value) {
      var str = JSON.stringify(value);
      localStorage.setItem("screen-price", str);
      state.screen.price = value;
    },

    setToken(state, token) {
      localStorage.setItem("Token", token);
      state.token = token;
      axios.defaults.headers.common["Authorization"] = "bearer " + token;
    },
    setUser(state, user) {
      state.user = { ...user };
    },
    openDialog(state) {
      state.confirmDialogOpen = true;
    },
    closeDialog(state) {
      state.confirmDialogOpen = false;
    },
    setAccept(state, accept) {
      state.acceptAction = () => {
        accept();
        this.commit("closeDialog");
      };
    },
    setSnackbar(state, snack) {
      state.snackbar = snack;
    },
    setCancel(state, cancel) {
      state.cancelAction = () => {
        cancel();
        this.commit("closeDialog");
      };
    },
    setMessage(state, msg) {
      state.additionalMessage = msg;
    },
    changeTheme(state) {
      state.darkTheme = !state.darkTheme;
    },
  },
  actions: {
    addPrice({ commit }, price) {
      commit("addPrice", price);
    },
    addSeats({ commit }, seats) {
      commit("addSeats", seats);
    },
    clearPrice({ commit }) {
      commit("clearPrice");
    },
    addTrainSegment({ commit, state }, newSegment) {
      commit("addTrainSegment", newSegment);
      console.log("action");
      console.log(this.trainSegments);
      if (state.trainSegments.length > 0) {
      }
    },
    removeTrainSegment({ commit, state }, index) {
      commit("removeTrainSegment", index);

      if (state.trainSegments.length === 0) {
      }
    },
    clearTrainSegments({ commit }) {
      commit("clearTrainSegments");
    },
    logout() {
      // Где она блядь?!
      localStorage.removeItem("Token");
      delete axios.defaults.headers.common["Authorization"];
      this.commit("setUser", null);
    },
    setActions({ commit }, { accept, cancel = () => {} }) {
      commit("setAccept", accept);
      commit("setCancel", cancel);
    },
    async updateToken() {
      console.log("updateToken");
      console.log("Обновляемый токен " + this.state.token);

      let response = await axios.get(
        `/Authorization/UpdateToken?token=${this.state.token}`
      );
      console.log(response);
      console.warn("Обновлен токен - " + response.data);
      this.commit("setToken", response.data);
    },
    async initializeScreen() {
      this.state.screen.isActive = await this.dispatch("getScreenIsActive");
      this.state.screen.price = await this.dispatch("getScreenPrice");
      this.state.screen.type = await this.dispatch("getCurrentScreenType");
    },
    async getScreenIsActive() {
      return toBool(localStorage.getItem("screen-active"));
    },
    async getCurrentScreenType() {
      return localStorage.getItem("screen-type");
    },
    async getScreenPrice() {
      return loadJSObject("screen-price");
    },

    async updateBallance() {
      if (!this.state.user.accountSetting.useBallance) return;
      let response = await axios.get(enums.Endpoints.Data.Ballance);
      this.state.user.accountSetting.ballance =
        response.data ?? this.state.user.accountSetting.ballance;
    },
  },

  getters: {
    api: (state) => state.apiAvia,
    token: (state) => state.token,
    user: (state) => state.user,
    getTrainSegments: (state) => () => state.trainSegments,
    getPrice: (state) => () => state.price,
    getSeats: (state) => () => state.seats,
  },
});

const loadJSObject = (key) => {
  const str = localStorage.getItem(key);
  if (!str || str.length == 0) return null;
  return JSON.parse(str);
};
