export default {
    methods: {
        openCloseShiftRequest(open = true){
            return {
                uuid: Date().toString(),
                request: [
                    {
                        type: open? 'openShift' : 'closeShift',
                        operator: {
                            name: this.$store.state.name,
                            vatin: this.$store.state.inn
                        }
                    }
                ]
            }
        },
        async openCloseShift(open = true) {
            let req = this.openCloseShiftRequest(open);
            let resp = await this.$axios.post(this.$store.state.kktUrl, req)
            console.log(resp)
        }
    }
}
