<template>
  <v-dialog
    max-width="600"
    transition="scroll-y-reverse-transition"
    v-model="$store.state.confirmDialogOpen"
    @click:outside="Cancel"
  >
    <v-card>
      <v-card-title class=" orange lighten-2 white--text justify-center ">
        <span v-if="!!$store.state.additionalMessage" class="title-modal"
          >Вы уверены, {{ $store.state.additionalMessage }}?</span
        >
        <span v-else>Вы уверены?</span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-actions class="justify-space-around">
        <v-btn color="green" @click="Ok" text x-large>
          <span class="text-h5">Да</span>
        </v-btn>
        <v-btn color="red" @click="Cancel" text x-large>
          <span class="text-h5">Нет</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  methods: {
    Cancel() {
      this.$store.commit("closeDialog");
      setTimeout(() => {
        this.$store.state.additionalMessage = null;
      }, 300);
    },
    Ok() {
      this.$store.state.acceptAction();
      setTimeout(() => {
        this.$store.state.additionalMessage = null;
      }, 300);
    },
  },
};
</script>

<style scoped>
.title-modal {
  white-space: pre-wrap !important;
  text-align: center;
  font-size: 25px !important;
  word-break: normal;
}
</style>
