<template>
  <div>
    <v-btn outlined color="orange" @click="openClose">
      {{
        $store.getters.shiftOpen
          ? `Close shift (${hours} : ${minutes})`
          : "Open shift"
      }}
    </v-btn>
    <shift-dialog v-model="shiftError"></shift-dialog>
  </div>
</template>

<script>
import KKT from "@/mixins/KKT";
import ShiftDialog from "@/components/UI/ShiftDialog";

export default {
  name: "KKTShift",
  components: { ShiftDialog },
  mixins: [KKT],
  data() {
    return {
      timer: Math.floor(
        parseInt(this.$store.state.shiftExpiry - Date.now()) / 1000
      ),
      shiftError: false,
    };
  },
  mounted() {
    setInterval(
      () =>
        (this.timer = Math.floor(
          parseInt(this.$store.state.shiftExpiry - Date.now()) / 1000
        )),
      100
    );
  },
  methods: {
    async openClose() {
      try {
        await this.openCloseShift(!this.$store.getters.shiftOpen);
        await this.$store.dispatch(
          "openCloseShift",
          !this.$store.getters.shiftOpen
        );
      } catch {
        this.shiftError = true;
      }
    },
  },
  computed: {
    hours() {
      return Math.floor(this.timer / 3600);
    },
    minutes() {
      return Math.floor((this.timer % 3600) / 60);
    },
  },
};
</script>

<style scoped></style>
