import store from "./store";
import axios from "@/services/axios.js";
import enums from "@/services/enums";

export default {
  toLogin: "/login",

  /** Пытается получить данные по токену при старте приложения */
  trySignIn: async () => {
    let token = localStorage.Token;
    if (!!token) {
      try {
        var response = await axios.get(
          enums.Endpoints.Authorization.LoginByToken + `?token=${token}`
        );
        store.commit("setToken", token);
        store.commit("setUser", response.data);
      } catch (error) {
        store.commit("setToken", "");
        store.commit("setUser", null);
      }
    } else {
      store.commit("setToken", "");
      store.commit("setUser", null);
    }
  },

  /** Проверяет валидность текущего токена  */
  checkToken: async () => {
    let token = store.getters.token;
    if (!!token) {
      var response = await axios.get(enums.Endpoints.Authorization.CheckToken + `?token=${token}`);
      store.commit("setUser", response.data);
      return true;
    }
    return false;
  },

  /** Вход в аккаунт по логину и паролю */
  signIn: async (email, password) => {
    let user = {
      Email: email,
      Password: password,
    };
    var response = await axios.post(enums.Endpoints.Authorization.Login, user);
    if (response.status === 200) {
      store.commit("setToken", response.data.token);
      store.commit("setUser", response.data.user);
      return true;
    }
    return false;
  },

  /** Удаляет данные о пользователе и токене */
  signOut: () => {
    store.commit("setToken", "");
    store.commit("setUser", null);
    localStorage.removeItem("isTestPult");
    delete axios.defaults.headers.common["Authorization"];
  },
};
